<template>
    <div class="editPrograms-header">
        <Button class="p-button-raised p-button-rounded p-button-danger" icon="pi pi-arrow-left" @click="goBack" />
        
        <!-- HEADER  -->
        <div class="editPrograms-container">
            <!-- <div class="editPrograms-main">
                <label> Show Details</label>
                <InputSwitch v-model="showDetails" />
            </div> -->
            <div class="editPrograms-main">
                <label>Show Years</label>
                <InputSwitch v-model="showYears" />
            </div>
            <div v-if="valuesOptions" class="editPrograms-main">
                <label>Values</label>
                <Dropdown 
                    style="width: 10rem; " 
                    v-model="selectedValueOption" 
                    :options="valuesOptions" 
                    optionLabel="name"
                    optionValue="id"
                    :class="( scenarioBaseIdTheme != selectedValueOption )?'valuesOptionsIsNotBaseScenario':'' " 
                >
                
                </Dropdown>
            </div>

            <div class="editPrograms-main">
                <MultiSelect 
                    :modelValue="selectedColumns" 
                    :options="columns" 
                    optionLabel="header"
                    @update:modelValue="onToggle"
                    placeholder="Select Columns" 
                    ref="selectAll"
                    filter
                >
                <template #header>
                    <!-- OnClickFunctionality -->
                        <!-- we need the ref selectAll -->
                        <!--  -->
                    <!-- <div class="absolute cursor-pointer top-3 left-10" @click="toggleSelectAll"> -->
                    <div class="checkbox-all-text">
                        Check all/none
                    </div>
                </template>

                <template #value>
                    <div>
                        Select Columns
                    </div>
                </template>
                </MultiSelect>
            </div>
        </div>
    </div>

    <div v-if="programs && programForm.length > 0" class="editMultiplesProgramsDataTableContainer">
        <!-- {{ programStatus }} -->
        <DataTable 
            :value="programs" 
            :loading="loading" 
            responsiveLayout="scroll" 
            showGridlines 
            removableSort
            dataKey="documentProgramId" 
            stripedRows 
            :rowClass="getRowClassName"

            resizableColumns 
            columnResizeMode="fit" 
            class="editMultiplesProgramsDataTable"
            
        >
        <!-- <template #header> 
            <div style="text-align:left">
                <MultiSelect 
                    :modelValue="selectedColumns" 
                    :options="columns" 
                    optionLabel="header"
                    @update:modelValue="onToggle"
                    display="chip" 
                    placeholder="Select Columns" 
                />
            </div>
        </template> -->

            <!-- resizableColumns 
            columnResizeMode="fit" 
            tableStyle="min-width: 50rem" -->

            <!-- <Column :header="'Save'">
                <template #body="slotProps">
                    <Button
                        type="button" 
                        icon="pi pi-check" 
                        label="Save"
                        class="p-button-outlined me-4" 
                        @click="saveProgram(slotProps.index)" 
                    />
                </template>
            </Column> -->

            <!-- ProgramName -->
            <Column :header="'Programme'" >
                <template #body="slotProps">
                    <InputText 
                        class="ColumnInput"
                        v-model.trim="programForm[slotProps.index].documentProgram.name"
                        v-on:input="setSave(slotProps, true)" 
                    />
                </template>
            </Column>

            <!-- vfor  -->
            <Column 
                v-for="(col, index) of selectedColumns" 
                :header="col.header"
                :field="col.field" 
                :key="col.field + '_' + index"
            >
                <template #body="slotProps">
                    <!-- Program Segmentation -->
                        <!-- Country Segmentation -->
                    <div v-if="col.header === 'Country'" >
                        <Dropdown 
                            v-model="country[slotProps.index][col.index]"
                            :options="getCountriesOptions(col.index)" 
                            optionLabel="name" 
                            optionValue="countryId"
                            :filter="true"
                            @change="setSegmItem(col.index, $event, slotProps)"
                            class="ColumnInput"
                        >
                        </Dropdown>
                    </div>

                    <!-- Base Segmentation -->
                    <div v-for="segmentation in topicSegmentations" :key="segmentation.name">
                        <div v-if="col.header === segmentation.name">
                            <Dropdown 
                                v-model="segmItems[slotProps.index][col.index]"
                                :options="getSegmentationsOptions(segmentation.segmentationItems)" 
                                optionLabel="name"
                                optionValue="segmentationItemId" 
                                :filter="true"
                                @change="setSave(slotProps, true)"
                                class="ColumnInput"
                            >
                            </Dropdown>
                        </div>
                    </div>
                    <!-- Program Detail -->
                    <div v-if="col.header === 'Product'">
                        <InputText 
                            class="ColumnInput"
                            v-model.trim="programForm[slotProps.index].product"
                            v-on:input="setSave(slotProps, true)" 
                        />
                    </div>

                    <div v-if="col.header === 'Manufacturer'">
                        <Dropdown 
                            v-model="programForm[slotProps.index].manufacturerId"
                            :options="companies"
                            optionLabel="name"
                            optionValue="companyId"
                            :filter="true"
                            placeholder="Select Manufacturer"
                            @change="setSave(slotProps, true)"
                            class="ColumnInput"
                        />
                    </div>
                    
                    <div v-else-if="col.header === 'CountryManufacturer'">
                        <!-- <InputText
                            class="ColumnInput" 
                            v-model.trim="programForm[slotProps.index].countryManufactured"
                            v-on:input="setSave(slotProps, true)" 
                        /> -->
                        <Dropdown 
                            v-model="programForm[slotProps.index].countryManufactured"
                            :options="countries"
                            optionLabel="name"
                            optionValue="name"
                            :filter="true"
                            placeHolder="Select Country Manufacturer"
                            @change="setSave(slotProps, true)"
                            class="ColumnInput"
                        />
                    </div>
                    
                    <!-- Remove Contract Year -->
                    <!-- <div v-else-if="col.header === 'Contract Year'">
                        <InputText 
                            class="ColumnInput"
                            v-model.trim="programForm[slotProps.index].contractYear"
                            v-on:input="setSave(slotProps, true)" 
                        />
                    </div> -->

                    <!-- Add programStartYear -->
                    <div v-else-if="col.header === 'Programme StartYear'">
                        <!-- <InputText 
                            class="ColumnInput"
                            v-model.trim="programForm[slotProps.index].contractYear"
                            v-on:input="setSave(slotProps, true)" 
                        /> -->
                            <Calendar
                                v-model="programForm[slotProps.index].programStartYear" 
                                view="year"
                                dateFormat="yy"
                                @date-select="setSave(slotProps, index)"
                                class="ColumnInput"
                            />

                    </div>

                    <!-- Add programEndYear -->
                    <div v-else-if="col.header ==='Programme EndYear'" >
                        <!-- <InputText 
                            class="ColumnInput"
                            v-model.trim="programForm[slotProps.index].contractYear"
                            v-on:input="setSave(slotProps, true)" 
                        /> -->
                        <Calendar 
                            v-model="programForm[slotProps.index].programEndYear"
                            view="year"
                            dateFormat="yy"
                            @date-select="setSave(slotProps, index)"
                            class="ColumnInput"
                        />
                    </div>
                    
                    <div v-else-if="col.header === 'Total Value US$'">
                        <InputText
                            class="ColumnInput" 
                            v-model.trim="programForm[slotProps.index].contractValue"
                            v-on:input="setSave(slotProps, true)" />
                    </div>

                    <div v-else-if="col.header === 'Total Quantity'">
                        <InputText 
                            class="ColumnInput"
                            v-model.trim="programForm[slotProps.index].totalQuantity"
                            v-on:input="setSave(slotProps, true)" />
                    </div>
                    
                    <div v-else-if="col.header === 'Delivery Year'">
                        <InputText 
                            class="ColumnInput"
                            v-model.trim="programForm[slotProps.index].deliveryYear"
                            v-on:input="setSave(slotProps, true)" 
                        />
                    </div>

                    <div v-else-if="col.header === 'Unit Price'">
                        <InputText 
                            class="ColumnInput"
                            v-model.trim="programForm[slotProps.index].unitPrice"
                            v-on:input="setSave(slotProps, true)" 
                        />
                    </div>

                    <div v-else-if="col.header === 'Status'">
                        <Dropdown
                        class="ColumnInput"
                                style="width: 15rem;"
                                v-model.trim="programForm[slotProps.index].documentProgramStatusId"
                                :options="programStatus"
                                optionLabel="status"
                                optionValue="documentProgramStatusId"
                                @change="setSave(slotProps, true)"
                            >
                        </Dropdown>
                    </div>

                </template>
            </Column>

            <div v-if="showYears">
                <!-- PrevButton -->
                <Column style="width: 1rem;">
                    <template #header>
                        <Button icon="pi pi-angle-left p-button-text"
                            style="margin-right: 0.5rem; width: 2rem; height: 2rem;" @click="prev(slotProps)" />
                    </template>
                    <template #body>
                        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 0.5rem;">
                            <label for="units"> $</label>
                            <label for="values"> #</label>
                        </div>
                    </template>
                </Column>

                <!-- rangeColumns -->
                <div v-for="(item, index) in values[0][p - 1]" :key="index">
                    <Column style="width: 7rem;">
                        <template #header>
                            <div style="
                                    width: 7rem; 
                                    display: flex; 
                                    justify-content: center; 
                                    align-items: center;">
                                <label>{{ item.year }}</label>
                            </div>
                        </template>

                        <template #body="slotProps">
                            <!-- value -->
                            <div style="
                                    display: grid;
                                    place-items: center;
                                    width: 95%;
                                ">
                                    <InputText 
                                        class="summaryText" 
                                        type="text" 
                                        min="0"
                                        v-model="values[slotProps.index][p - 1][index].value"
                                        v-on:input="(selectedValueOption === 'documentProgramValues')?changeValue($event, slotProps, index):changeScenarioValue($event, slotProps, index)" 
                                    />
                                    

                            </div>
                            <!-- Units -->
                            <div style="
                                    display: grid; 
                                    place-items: center;
                                    width: 95%;
                                ">
                                    <InputText 
                                        class="summaryText" 
                                        type="number" 
                                        min="0"
                                        v-model:value="values[slotProps.index][p - 1][index].unit"
                                        @input="(selectedValueOption === 'documentProgramValues')?changeUnit($event, slotProps, index):changeScenarioUnit($event, slotProps, index)" />
                            </div>
                        </template>
                    </Column>
                </div>
                <!-- NextButton -->
                <Column>
                    <template #header>
                        <Button icon="pi pi-angle-right" style="margin-left: 0.5rem; width: 2rem; height: 2rem;"
                            @click="next(slotProps)" />
                    </template>
                </Column>
            </div>
        </DataTable>
    </div>
    <div v-else>
        <div class="row">
            <div class="col-12 py-4 d-flex justify-content-center">
                <ProgressSpinner />
            </div>
        </div>
    </div>
</template>

<script>
// PrimeVue
import ProgressSpinner from 'primevue/progressspinner';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import InputSwitch from 'primevue/inputswitch';
import MultiSelect from 'primevue/multiselect';
import Calendar from 'primevue/calendar';

// service
import { editProgramOverview } from './services/program.service';

export default {
    name: 'EditMultiplePrograms',
    components: {
        ProgressSpinner,
        DataTable,
        Column,
        InputText,
        Button,
        Dropdown,
        InputSwitch,
        MultiSelect,
        Calendar,
    },
    data() {
        return {
            loading: false,
            // ValuesOptions is an array of program Values, and summaries values
            valuesOptions: [],
            selectedValueOption: null,
            // years
            minYear: null,
            maxYear: null,
            programForm: [],
            // segmentationItems
            segmItems: [],
            country: [],
            region: [],
            // values and Units, Value,units loading, timeCountFor AutoSave 
            values: [],
            loadingSave: [],
            loadingSaveDetail: [],
            loadingSaveValue: [],
            timeCountDetail: [],
            timeCountValueUnit: [],
            // showDetails , showYears
            showDetails: false,
            showYears: false,
            // arrayDiff For Carousel , P point of the years
            arrayDiff: 9,
            p: 1,

            //ColumnsHeader
            columns : [
            ],
            toggleColumnsOperation:[],
            selectedColumns :[],

            //Remember Columns
            sortProgramsColumn: null,
            
            // scenarioBaseId for change color of optionValues 
            scenarioBaseIdTheme: null
        }
    },

    async mounted() {

        await this.getProgramStatus(); 
        await this.getProgramsByDocTopProgIds();
        await this.getCountries();
        await this.getCompanies();
        await this.getSegmentations();
        this.getFistLastYear();
        this.orderSegmentations();
        this.getValuesOptions();

        // createColumns 
        this.setHeaderColumns();

        //Remember ProgramsColumn
        this.setProgramsColumn();
    },

    computed: {
        selectedTopic() {
            return this.$store.getters.obtainSelectedTopic;
        },
        countries() {
            return this.$store.getters.obtainCountries;
        },
        companies(){
            let companiesOrder = this.$store.getters.obtainCompanies;

            companiesOrder.sort(function(a,b){
                if(a.name.toLowerCase() < b.name.toLowerCase()){return -1}
                if(a.name.toLowerCase() > b.name.toLowerCase()){return 1}
                return 0;
            })
            return companiesOrder;
        },
        programs() {
            return this.$store.getters.obtainProgramByDocTopProgIds;
        },
        documentProgramTopicIds() {
            return this.$store.getters.obtainDocumentProgramTopicIds;
        },
        topicSegmentations() {
            return this.$store.getters.obtainSegmentations;
        },
        programStatus(){
            return  this.$store.getters.obtainProgramStatus;
        },
        // Remember ProgramsColumn
        programsColumn(){
            return this.$store.getters.obtainProgramsColumn;
        }

    },
    methods: {
        // onCLick functionality
        // toggleSelectAll() {
        //     // check all selected then remove all selected items
        //     if (this.$refs.selectAll.allSelected) {
        //         this.selectedColumns = [];
        //         return;
        //     }

        //     // else add all items
        //     this.selectedColumns = this.toggleColumnsOperation;
        // },

        onToggle(value) {
            // this.selectedColumns = this.columns.filter(col => value.includes(col));
            this.selectedColumns = value
            //when use Function we store.dispatch Vuex for setting columns 
            this.$store.dispatch('updateProgramsColumn',value);
        },
        setHeaderColumns(){
            this.columns = [
                {header:'Product', field:'Product'},
            ] 

            this.topicSegmentations.map((topicSegmentation , index) =>
            {
                if(topicSegmentation.isRegional){
                    this.columns.push(
                        {
                            index: index,
                            header: 'Country',
                            field: 'Country'
                        }
                    )
                }
                else{
                    this.columns.push(
                        {
                            index: index,
                            header: topicSegmentation.name,
                            field: topicSegmentation.name
                        }
                    )
                }
            })

            const columnsDetail =
            [
                {header:'Manufacturer', field:'Manufacturer'},
                {header:'CountryManufacturer', field:'CountryManufacturer'},
                // Remove ContractYear
                // {header:'Contract Year', field:'Contract Year'},
                // Add StartYear, EndYear
                {header:'Programme StartYear', field:'Programme StartYear'},
                {header:'Programme EndYear', field:'Programme EndYear'},

                {header:'Total Value US$', field:'Total Value US$'},
                {header:'Total Quantity' ,field:'Total Quantity'},
                {header:'Delivery Year' , field:'Delivery Year'},
                {header:'Unit Price' , field:'Unit Price'},
                {header:'Status', field:'Status'},
            ]

            this.columns = this.columns.concat(columnsDetail);
            this.toggleColumnsOperation = this.columns;
            this.selectedColumns= this.columns
        },

        setProgramsColumn(){
            try {
                if(this.programsColumn.length > 0){
                    this.selectedColumns = this.programsColumn
                }
            } catch (error) {
               console.log(error); 
            }
        },

        goBack() {
            this.$router.go(-1);
        },
        getValuesOptions() {
            this.valuesOptions.push({
                name: 'ProgramValues',
                id: 'documentProgramValues',
            })
            this.selectedTopic.scenarios.map(scenario => {
                const scenarioValue = {
                    name: scenario.name,
                    id: scenario.scenarioId,
                }
                this.valuesOptions.push(scenarioValue)
            })
            // console.log(this.selectedTopic.scenarios);
            // getscenarioBase 
            const scenarioBaseId = this.valuesOptions.find(valuesOption => valuesOption.name === 'Scenario Base' || valuesOption.name === 'Base Scenario' )
            // console.log(scenarioBaseId);

            this.scenarioBaseIdTheme = scenarioBaseId.id

            // set BaseScenario toSelectecValueOption
            // this.selectedValueOption = 'documentProgramValues';
            this.selectedValueOption = scenarioBaseId.id;
        },
        // async onMounted
        async getProgramsByDocTopProgIds() {// get MultiplesPrograms
            try {
                this.loading = true;
                await this.$store.dispatch('obtainProgramsByDocTopProgIds', this.documentProgramTopicIds);
                this.loading = false;

            } catch (error) {
                this.loading = false;
                console.log(error);
            }
        },
        async getProgramStatus(){
            try {
                this.loading = true; 
                await this.$store.dispatch('obtainProgramStatus');
                this.loading = false;
            } catch (error) {
                this.loading = false; 
                console.log(error);
            }
        },
        async getCountries() {  // get Countries       
            try {
                this.loading = true;
                await this.$store.dispatch('obtainCountries');
                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
        },
        async getCompanies(){
            try {
                this.loading = true; 
                await this.$store.dispatch('obtainCompanies');
                this.loading = false; 
            } catch (error) {
                this.loading = false; 
            }

        },
        async getSegmentations() { //GetTopicSegmentations
            try {
                this.loading = true;
                await this.$store.dispatch('obtainSegmentations', this.selectedTopic.topicId);
                this.loading = false
            } catch (error) {
                this.loading = false;
            }
        },

        getCountryName(countryId) { //get Countries Name from countryId
            const a = this.countries.find(country => country.countryId === countryId);
            if (a) {
                return a.name
            }
        },

        getCountriesOptions(index) { //return Countries options for drowpdown
            let countriesList = []
            this.topicSegmentations.filter(se => se.isRegional)[index].segmentationItems.map(si => si.region.regionCountries.map(rc => countriesList.push(rc.country)))
            
            countriesList.sort(function (a,b){
                if(a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                if(a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                return 0;
            }); 
            return countriesList.flat()
        },

        //filterSegmentationOptions
        getSegmentationsOptions(segmentationItems){
            let segmentationItemsSort = segmentationItems
            segmentationItemsSort.sort(function (a,b){
                if(a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                if(a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                return 0;
            });
            return segmentationItemsSort;
        },


        setSegmItem(index, event, slotProps) { // OnChange Segmentations  
            const segmentationItemId = this.topicSegmentations.filter(se => se.isRegional)[index].segmentationItems.filter(si => si.region.regionCountries.some(c => c.country.countryId === event.value)).map(filtered => filtered.segmentationItemId)
            const regionId = this.topicSegmentations.filter(se => se.isRegional)[index].segmentationItems.filter(si => si.region.regionCountries.some(c => c.country.countryId === event.value)).map(filtered => filtered.region.regionId)

            this.region[slotProps.index] = regionId
            this.segmItems[slotProps.index][index] = segmentationItemId[0]
            
            this.setSave(slotProps, true);
        },

        // sort segmentations 
        orderSegmentations() {
            this.programForm.map((program, programIndex) => {
                let array = []
                program.docProgramTopicSegItems.map(segmItem => {
                    this.topicSegmentations.map((topicSeg, index) => {
                        if (topicSeg.segmentationId === segmItem.segmentationItem.segmentationId) {
                            array[index] = segmItem.segmentationItemId
                        }
                    })
                })
                this.segmItems[programIndex] = array
            })
        },

        // Values and Units
        setValuesUnits() {
            this.programForm.map((program, programIndex) => {
                let valuesArray = program.documentProgramValues;
                this.values[programIndex] = this.getSummary(valuesArray);
            })
        },
        setScenariosValuesUnits(scenarioId) {
            this.programForm.map((program, programIndex) => {
                const programTopicScenario = program.programTopicScenarios.filter((progTopSce) => {
                    return progTopSce.scenarioId === scenarioId;
                })[0]
                // })[0].programTopicScenarioValues
                if(programTopicScenario){
                    this.values[programIndex] = this.getSummary(programTopicScenario.programTopicScenarioValues)
                }
                else{
                    this.values[programIndex] = this.getSummary([])
                }
            })
            // console.log(scenarioId);
        },
        getSummary(valuesArray) {
            let min = this.minYear;
            let max = this.maxYear;

            const sas = valuesArray;
            // new GetSummary
            let ara2 = []

            if (sas && sas.length > 0) {
                let sum = sas
                sum = sum.sort((a, b) => a.year - b.year)
                let count = 0;
                for (let mIndex = min; mIndex <= max; mIndex += this.arrayDiff + 1) {
                    let ara = []
                    for (let i = 0; i <= this.arrayDiff; i++) {
                        if (sum[count] != undefined && sum[count].year === min + i) {
                            if (min + i === sum[count].year) {
                                ara.push(sum[count])
                                count += 1
                            }
                        }
                        else {
                            const marketValues = {
                                "value": null,
                                "unit": null,
                                "total": 0,
                                "year": min + i
                            }
                            ara.push(marketValues)
                        }
                        if (i === this.arrayDiff) {
                            ara2.push(ara)
                            min += this.arrayDiff + 1
                        }
                    }
                    // this.array = ara2
                }
                return ara2
            }
            else {
                for (let mIndex = min; mIndex <= max; mIndex += this.arrayDiff + 1) {
                    let ara = []
                    for (let i = 0; i <= this.arrayDiff; i++) {
                        const marketValues = {
                            "value": null,
                            "unit": null,
                            "year": min + i,
                        }
                        ara.push(marketValues)
                        if (i === this.arrayDiff) {
                            ara2.push(ara)
                            min += this.arrayDiff + 1
                        }
                    }

                    // this.array = ara2
                }
                return ara2
            }
        },

        prev() {
            if (1 === this.p) {
                const firstYear = this.values[0][this.p - 1][0].year //2022
                const prevRangeFirstYear = firstYear - (this.arrayDiff + 1)//2017 2022- diffYear
                this.minYear = prevRangeFirstYear;

                this.values.map(value => {
                    let pArray = []
                    for (let i = 0; i <= this.arrayDiff; i++) {
                        const marketValues = {
                            "value": null,
                            "unit": null,
                            "year": prevRangeFirstYear + i,
                        }
                        pArray.push(marketValues)
                    }
                    value.splice(0, 0, [...pArray])
                }
                )
            }
            else {
                this.p = this.p - 1;
            }
        },
        next() {
            if (this.values[0].length === this.p) {
                const lastYear = this.values[0][this.p - 1][this.values[0][this.p - 1].length - 1].year + 1;
                this.values.map(value => {
                    let pArray = []
                    for (let i = 0; i <= this.arrayDiff; i++) {
                        const marketValues = {
                            "value": null,
                            "unit": null,
                            "year": lastYear + i,
                        }
                        pArray.push(marketValues)
                    }
                    value.push([...pArray])
                    this.maxYear = pArray[pArray.length - 1].year
                })
                this.p = this.p + 1
            }
            else {
                this.p = this.p + 1;
            }
        },

        // get firstYear and last year
        getFistLastYear() {
            let a = [];
            let b = [];
            const currentYear = new Date().getFullYear()
            this.programs.map(program => {
                if (program.documentProgramValues[0]) {
                    a.push(program.documentProgramValues[0]);
                    b.push(program.documentProgramValues[program.documentProgramValues.length - 1]);
                }
                else {
                    a.push({
                        "value": 0,
                        "unit": 0,
                        "year": currentYear
                    });
                    b.push(
                        {
                            "value": 0,
                            "unit": 0,
                            "year": currentYear + 9
                        }
                    );
                }
            })
            const min = a.map(a => a.year);
            const max = b.map(a => a.year);

            this.minYear = min.reduce((a, b) => {
                return a < b ? a : b;
            })
            this.maxYear = max.reduce((a, b) => {
                return a > b ? a : b;
            })
        },
        //value , units change
        changeUnit(event, slotProps, index) {
            // this.values[slotProps.index][this.p-1][index] = { ...this.values[slotProps.index][this.p-1][index] , "unit":event.target.value };
            this.values[slotProps.index][this.p - 1][index].unit = event.target.value
            this.setSave(slotProps, false);
        },

        changeValue(event, slotProps, index) {
            let numericValues = event.target.value.replace(/[^\d.]|[.](?=.*[.])|(\.\d{4,})/g , '');
            // console.log(numericValues);
            //Validation If save or not
            const a = this.values[slotProps.index][this.p - 1][index].value
            const b = numericValues
            
            // this.values[slotProps.index][this.p-1][index] = { ...this.values[slotProps.index][this.p-1][index] , "value":event.target.value };
            // this.values[slotProps.index][this.p - 1][index].value = event.target.value
            this.values[slotProps.index][this.p - 1][index].value = numericValues
            // console.log(a , b , this.values[slotProps.index][this.p - 1][index].value);
            if(a === b ){
                // console.log('saveData');
                this.setSave(slotProps, false);
            }

        },

        changeScenarioValue(event, slotProps, index){
            let numericValues = event.target.value.replace(/[^\d.]|[.](?=.*[.])|(\.\d{4,})/g , '');

            // for validation if save or not
            const a = this.values[slotProps.index][this.p - 1][index].value
            const b = numericValues


            this.values[slotProps.index][this.p - 1][index].value = numericValues
            // this.values[slotProps.index][this.p - 1][index].value = event.target.value
            if(a === b ){
                // console.log('saveData scenario');
                this.setSaveScenario(slotProps)
            }
        },
        changeScenarioUnit(event, slotProps, index){
            this.values[slotProps.index][this.p - 1][index].unit = event.target.value
            this.setSaveScenario(slotProps)
        },

        // saveButton
        // async saveProgram(slotPropsIndex) {
        //     const slotPropsIndexVariable = slotPropsIndex
        //     await this.saveProgramDetails(slotPropsIndexVariable);
        //     await this.saveProgramUnitsValues(slotPropsIndexVariable);
        // },
        async saveProgramDetails(slotPropsIndex) {
            let segmentationItems = []
            this.segmItems[slotPropsIndex].map((item, segmIndex) => {
                const regionCountry = {
                    'regionId': this.region[slotPropsIndex][segmIndex],
                    'countryId': this.country[slotPropsIndex][segmIndex],
                }

                if (!this.country[slotPropsIndex][segmIndex]) {
                    const segmentationItems1 = {
                        'segmentationItemId': item,
                        'regionCountry': null,
                    }
                    segmentationItems[segmIndex] = segmentationItems1
                }
                else {
                    const segmentationItems1 = {
                        'segmentationItemId': item,
                        'regionCountry': regionCountry,
                    }
                    segmentationItems[segmIndex] = segmentationItems1
                }
            })

            let currentDate = new Date();

            let documentProgramTopics = {
                'documentProgramTopicId': this.programForm[slotPropsIndex].documentProgramTopicId,
                'topicId': this.programForm[slotPropsIndex].topicId,
                // 'programDate': this.programForm[slotPropsIndex].programDate,
                'programDate': currentDate,
                'programSummary': this.programForm[slotPropsIndex].programSummary,
                'Product': this.programForm[slotPropsIndex].product,
                //Replace manufacturer to manufacturerId
                // 'manufacturer': this.programForm[slotPropsIndex].manufacturer,
                'manufacturerId':this.programForm[slotPropsIndex].manufacturerId,
                'CountryManufactured': this.programForm[slotPropsIndex].countryManufactured,
                //we are not using contractYear
                // 'ContractYear': this.programForm[slotPropsIndex].contractYear,
                'programStartYear':(this.programForm[slotPropsIndex].programStartYear)? this.programForm[slotPropsIndex].programStartYear.getFullYear() :null,
                'programEndYear': (this.programForm[slotPropsIndex].programEndYear) ? this.programForm[slotPropsIndex].programEndYear.getFullYear() :null,

                'ContractValue': this.programForm[slotPropsIndex].contractValue,
                'TotalQuantity': this.programForm[slotPropsIndex].totalQuantity,
                'deliveryYear': this.programForm[slotPropsIndex].deliveryYear,
                'UnitPrice': this.programForm[slotPropsIndex].unitPrice,
                docProgramTopicSegItems: segmentationItems,
                'documentProgramStatusId': this.programForm[slotPropsIndex].documentProgramStatusId,
            }
            let programForm = {
                'name': this.programForm[slotPropsIndex].documentProgram.name,
                'documentProgramTopics': [documentProgramTopics],
                'documentProgramId': this.programForm[slotPropsIndex].documentProgramId
            }
            try {
                await this.$store.dispatch('modifyProgram', programForm);
            } catch (error) {
                console.log(error);
            }

        },
        async saveProgramUnitsValues(slotPropsIndex) {
            let values = this.values[slotPropsIndex];
            values = values.flat()
            values = values.filter(a => {
                // return a.value !== 0 || a.unit !== 0
                return (a.value !== null || a.unit !== null) && (a.value !== "" && a.unit !== "");
            }
            )

            let form = {
                // 'documentProgramValues': this.values[slotPropsIndex],
                'documentProgramValues': values,
                'programTopicScenarios': null,
                // 'programTopicUnits': this.units[slotPropsIndex] , 
            }
            try {
                const response = await editProgramOverview(this.programForm[slotPropsIndex].documentProgramTopicId, form);
                // update the data in units and value
                const programValues = response.data.documentProgramValues.sort((a, b) => a.year - b.year)
                // this.values[slotPropsIndex] = this.getSummary(programValues);
                this.programForm[slotPropsIndex].documentProgramValues= programValues;

            } catch (error) {
                console.log(error);
            }
        },

        async saveProgramScenarioUnitsValues(slotPropsIndex){
            let values = this.values[slotPropsIndex];
            values = values.flat();
            values = values.filter( a=> {
                return (a.value !== null || a.unit !== null) && (a.value !== "" && a.unit !== "");
            })

            const documentProgramValues = this.programForm[slotPropsIndex].documentProgramValues

            const formObject = {
                scenarioId : this.selectedValueOption,
                programTopicScenarioValues: values
            }
            let form = {
                'documentProgramValues': documentProgramValues,
                'programTopicScenarios': [formObject],
            }
            try {
                const response =  await editProgramOverview(this.programForm[slotPropsIndex].documentProgramTopicId, form );
                // console.log(response);
                const programValues = response.data.programTopicScenarios
                this.programForm[slotPropsIndex].programTopicScenarios = programValues

            } catch (error) {
                console.log(error);
            }
        },

        // autoSave
        setSave(slotProps, detail) {
            this.loadingSave[slotProps.index] = slotProps.data.documentProgramId
            if (detail) {
                this.loadingSaveDetail[slotProps.index] = true
                if (this.timeCountDetail[slotProps.index]) {
                    clearTimeout(this.timeCountDetail[slotProps.index]);
                }
                this.timeCountDetail[slotProps.index] = setTimeout(() => {
                    // console.log('setSave1');
                    this.saveData(slotProps.index, detail)
                }, 3000)
                // }, 3000)
            }
            else {
                this.loadingSaveValue[slotProps.index] = true
                if (this.timeCountValueUnit[slotProps.index]) {
                    clearTimeout(this.timeCountValueUnit[slotProps.index]);
                }
                this.timeCountValueUnit[slotProps.index] = setTimeout(() => {

                    this.saveData(slotProps.index, detail)
                }, 3000)
                // }, 3000)
            }
        },
        async saveData(slotPropsIndex, detail) {
            if (detail) {
                // console.log('setSave2');
                await this.saveProgramDetails(slotPropsIndex)
                this.loadingSaveDetail[slotPropsIndex] = false
            }
            else {
                await this.saveProgramUnitsValues(slotPropsIndex)
                this.loadingSaveValue[slotPropsIndex] = false
            }

            if (!this.loadingSaveDetail[slotPropsIndex] && !this.loadingSaveValue[slotPropsIndex]) {
                this.loadingSave[slotPropsIndex] = false;
            }
        },

        setSaveScenario(slotProps){
            this.loadingSave[slotProps.index]= slotProps.data.documentProgramId
            this.loadingSaveValue[slotProps.index] = true
            if(this.timeCountDetail[slotProps.index]){
                clearTimeout(this.timeCountDetail[slotProps.index]);
            }
            this.timeCountDetail[slotProps.index] = setTimeout(() => {
                this.saveDataScenario(slotProps.index)
            }, 3000);
        },

        async saveDataScenario(slotPropsIndex) {
            await this.saveProgramScenarioUnitsValues(slotPropsIndex);
            this.loadingSaveValue[slotPropsIndex]= false
            if (!this.loadingSaveDetail[slotPropsIndex] && !this.loadingSaveValue[slotPropsIndex]) {
                this.loadingSave[slotPropsIndex] = false;
            }
        },

        // change Color when loading
        getRowClassName(rowData) {
            return this.loadingSave.some(item => item === rowData.documentProgramId) ? 'highlighted-row' : '';
        }
    },
    watch: {
        async documentProgramTopicIds(newVal) {
            if (newVal) {
                await this.getProgramsByDocTopProgIds();
            }
        },
        programs(newVal) {
            if (newVal) {
                this.programForm = [...newVal];


                this.programForm.map((program) => {
                    const regionSegm = program.docProgramTopicSegItems.filter(docProgramTopicSegItem => docProgramTopicSegItem.regionCountry != null)[0]
                    if (regionSegm) {
                        this.country.push([regionSegm.regionCountry.countryId])
                        this.region.push([regionSegm.regionCountry.regionId])
                        this.segmItems.push([])
                    }
                    else {
                        this.country.push([])
                        this.region.push([])
                        this.segmItems.push([])
                    }
                })
                //Parse Int or string to Datetime for use The Calendar
                this.programForm.map((program, index) => {
                    const StartYear = (program.programStartYear) ? new Date(program.programStartYear, 1, 1) : null 
                    const EndYear = (program.programEndYear)? new Date(program.programEndYear ,1 ,1 ) : null
                    this.programForm[index].programStartYear =  StartYear;
                    this.programForm[index].programEndYear = EndYear; 
                })
                // console.log(this.programForm);
            }
        },
        // toDO Create watch when change value Options 
        selectedValueOption(newVal) {
            if (newVal) {
                if (newVal !== 'documentProgramValues') {
                    // anotherFunction 
                    this.setScenariosValuesUnits(newVal);
                    // console.log(this.programForm);
                }
                else {
                    this.setValuesUnits();
                }
            }
        }
    }
}
</script>

<style >
.highlighted-row {
    background-color: yellow !important;
}

/* .p-datatable .p-datatable-tbody > tr {
        background: red;
        color: rgba(0, 0, 0, 0.87);
        transition: none; 
    } */

.p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even) {
    background: #ececec;
}

.p-datatable .p-datatable-tbody>tr {
    background: #ffffff;
}


.editMultiplesProgramsDataTable .p-datatable-wrapper{
    transform:rotateX(180deg);
    -ms-transform:rotateX(180deg); /* IE 9 */
    -webkit-transform:rotateX(180deg); /* Safari and Chrome */

}
.editMultiplesProgramsDataTable .p-datatable-wrapper .p-datatable-table{
    transform:rotateX(180deg);
    -ms-transform:rotateX(180deg); /* IE 9 */
    -webkit-transform:rotateX(180deg); /* Safari and Chrome */
}

</style>

<style scoped>

.ColumnInput{
    width: 100% !important;
}

.editPrograms-header {
    display: flex;
    align-items: center;
    margin: 2rem 2rem;
    gap: 3rem;
}

.editPrograms-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    /* justify-content: center; */
    /* align-items: center; */
}

.editPrograms-main {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

/* Chrome, Safari , Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    /* not native */
    margin: 0.3rem;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
    /* not native */
}

/* showYears */
/* .p-inputtext {
    padding: 0.2rem 1.7rem !important;
} */

.summaryText {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    /* border-bottom: 0; */
    border-bottom: solid 1px rgb(192, 192, 192);
    border-radius: 0px;

    background-color: rgba(255, 255, 255, 0.1);
    width: 7rem;
    text-align: right;
}

/* EditsmultiSelect Header */

.checkbox-all-text{
    position: absolute;
    left: 40px;
    top: 7.5%;

}

.valuesOptionsIsNotBaseScenario{
    background-color: lightskyblue;
}

</style>
